// Block forms_type //
// Block forms_type //
// Block forms_type //


$form_list_new_applications = $('#form-list-new-applications');

$ssu_forms_type_single = $('#ssu-forms-type_single');
$ssu_forms_type_multi = $('#ssu-forms-type_multi');
$forms_type = $('#forms_type');

$('body').on('click', '#ssu-forms-type_single', function (e) {
    e.preventDefault();

    $ssu_forms_type_multi.removeClass('ssu-forms-type__btn_active');
    $(this).addClass('ssu-forms-type__btn_active');
    $forms_type.attr('value', 'single');

    $form_list_new_applications.submit();
});

$('body').on('click', '#ssu-forms-type_multi', function (e) {
    e.preventDefault();

    $ssu_forms_type_single.removeClass('ssu-forms-type__btn_active');
    $(this).addClass('ssu-forms-type__btn_active');
    $forms_type.attr('value', 'multi');

    $form_list_new_applications.submit();
});


// Block forms_status //
// Block forms_status //
// Block forms_status //

$ssu_forms_status_all_forms = $('#ssu-forms-status_all-forms');
$ssu_forms_status_new_forms = $('#ssu-forms-status_new-forms');
$ssu_forms_status_accep_forms = $('#ssu-forms-status_accept-forms');
$ssu_forms_status_reject_forms = $('#ssu-forms-status_reject-forms');
$forms_status = $('#forms_status');

$('body').on('click', '#ssu-forms-status_all-forms', function (e) {
    e.preventDefault();

    $ssu_forms_status_new_forms.removeClass('ssu-forms-status__btn_new-forms_active');
    $ssu_forms_status_accep_forms.removeClass('ssu-forms-status__btn_accept-forms_active');
    $ssu_forms_status_reject_forms.removeClass('ssu-forms-status__btn_reject-forms_active');
    $(this).addClass('ssu-forms-status__btn_new-forms_active');
    $forms_status.attr('value', 'all');

    $form_list_new_applications.submit();
});

$('body').on('click', '#ssu-forms-status_new-forms', function (e) {
    e.preventDefault();

    $ssu_forms_status_all_forms.removeClass('ssu-forms-status__btn_all-forms_active');
    $ssu_forms_status_accep_forms.removeClass('ssu-forms-status__btn_accept-forms_active');
    $ssu_forms_status_reject_forms.removeClass('ssu-forms-status__btn_reject-forms_active');
    $(this).addClass('ssu-forms-status__btn_new-forms_active');
    $forms_status.attr('value', '2');

    $form_list_new_applications.submit();
});

$('body').on('click', '#ssu-forms-status_accept-forms', function (e) {
    e.preventDefault();

    $ssu_forms_status_all_forms.removeClass('ssu-forms-status__btn_all-forms_active');
    $ssu_forms_status_new_forms.removeClass('ssu-forms-status__btn_new-forms_active');
    $ssu_forms_status_reject_forms.removeClass('ssu-forms-status__btn_reject-forms_active');
    $(this).addClass('ssu-forms-status__btn_accept-forms_active');
    $forms_status.attr('value', '3');

    $form_list_new_applications.submit();
});

$('body').on('click', '#ssu-forms-status_reject-forms', function (e) {
    e.preventDefault();

    $ssu_forms_status_all_forms.removeClass('ssu-forms-status__btn_all-forms_active');
    $ssu_forms_status_accep_forms.removeClass('ssu-forms-status__btn_accept-forms_active');
    $ssu_forms_status_new_forms.removeClass('ssu-forms-status__btn_new-forms_active');
    $(this).addClass('ssu-forms-status__btn_reject-forms_active');
    $forms_status.attr('value', '4');

    $form_list_new_applications.submit();
});
