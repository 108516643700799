// формируем новые поля
jQuery('.ssu-table-elements').on('click','.btn-plus-js',function(){
    var element_plus_input = $('#ssu-table-elements__plus_input');
    var value_plus_input = element_plus_input.val();
    element_plus_input.val('');

    jQuery('.ssu-table-elements__plus').before(
        '<tr class="ssu-table-elements__minus">' +
            '<td class="ssu-table-elements__minus__td-input w-100">' +
                '<input type="text" class="input-style w-100" name="label_sub_elements[]" value="'+value_plus_input+'">' +
            '</td>' +
            '<td class="ssu-table-elements__minus__td-btn pull-right btn-minus-js">' +
                '<span class="glyphicon glyphicon-minus"></span>' +
            '</td>' +
        '</tr>'
    );
});
// on - так как элемент динамически создан и обычный обработчик с ним не работает
jQuery(document).on('click', '.btn-minus-js', function(){
    jQuery( this ).closest( 'tr' ).remove(); // удаление строки с полями
});

// проверка активное/неактивное поле после выбора select
$('#elements-js').change(function () {
    check_type_field();
});

// проверка активное/неактивное поле возврата на стр в случае ошибки если форма заполнена неверно
$(document).ready(function () {
    check_type_field();
});

function check_type_field() {
    var minus_tr = $('.ssu-table-elements__minus');
    var plus_input = $('#ssu-table-elements__plus_input');
    var plus_btn = $('.ssu-table-elements__plus__td-btn');
    var id_elements = $('#elements-js :selected').val();

    var select_list = $('#select-list');
    var select_list_required = $('.select-list-required');

    // если id_elements = radio, select, checkbox
    switch (id_elements) {
        case '4':
        case '5':
        case '6':
            select_list_required.remove();
            select_list.prepend('<span class="red select-list-required">*</span>');
            plus_input.attr('disabled', false).attr('required',true);
            plus_btn.addClass('btn-plus-js');
            break;

        default:
            plus_input.attr('disabled', true).attr('required',false).val('');
            minus_tr.remove();
            select_list_required.remove();
            plus_btn.removeClass('btn-plus-js');
            break;
    }
}